import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { Document, PDFViewer, Page, View, Text, StyleSheet, Image } from '@react-pdf/renderer';
import { useCallback, useEffect, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import { FlowVendaTO, StatusPedido, TFlowCorImpressaoTO, TFlowProdutoImpressaoTO, TFlowTamanhoImpressaoTO, TipoDesconto } from 'pages/registra-venda/registra-venda.model';
import { currencyOf, showOnlyDate } from 'utils/util';
import { IPagamentoRow } from 'pages/pagamento/pagamento.model';

type TInitialState = {
    flowVendaTO: FlowVendaTO;
};

const initialState: TInitialState = {
    flowVendaTO: {
        uuid: "",
        valorTotalBruto: 0,
        valorTotalLiquido: 0,
        valorTotalLiquidoTroca: 0,
        quantidadeTotal: 0,
        quantidadeTotalTroca: 0,
        emissao: "",
        entrega: "",
        numero: "",
        numeroEntrega: 0,
        cliente: null,
        vendedores: [],
        formaComissao: null,
        finalidadePedido: null,
        parcelas: [],
        condicoesPagamento: [],
        itensPagamento: [],
        itensPagamentoDevolucao: [],
        itens: [],
        itensTroca: [],
        cpfNota: null,
        pedidoDesconto: {
            uuidAutorizador: "",
            valorDesconto: 0,
            percentualDesconto: 0,
            tipoDesconto: TipoDesconto.PERCENTUAL
        },
        tabelaPreco: null,
        deposito: null,
        statusPedido: StatusPedido.ABERTO,
        consultados: [],
        estabelecimento: {
            nomeFantasia: "",
            telefone: "",
            endereco: "",
            inscricaoEstadual: "",
            complemento: "",
            municipio: "",
            email: "",
            cep: "",
        },
        flowItens: {
            produtos: [],
            condicaoPagamentos: []
        },
        transportador: {
            frete: {
                codigo: "",
                descricao: ""
            },
            transportadora: {
                descritivo: "",
                uuid: ""
            },
            placa: "",
            uf: "",
            pesoLiquido: null,
            pesoBruto: null,
            quantidade: null,
            especie: "",
            marca: "",
            numeroVolumes: null,
            valorFrete: null,
            valorSeguro: null
        },
        parcelasCrediario: [],
        observacao: "",
        isVisualizar: false
    }
};

const getCurrentTime = (): string => {
    const now: Date = new Date();
    const hours: number = now.getHours();
    const minutes: number = now.getMinutes();

    const formattedHours: string = hours < 10 ? '0' + hours : hours.toString();
    const formattedMinutes: string = minutes < 10 ? '0' + minutes : minutes.toString();

    return `${formattedHours}:${formattedMinutes}`;
};



const ImprimirA4 = (props: { dados: FlowVendaTO; onGoBack: () => void; }) => {
    const userInfo = useAppSelector(e => e.token.USER_INFO);
    const { descritivo: estabelecimentoDescritivo } = useAppSelector(e => e.estabelecimentoSelecionado);
    const [stateLocal, setStateLocal] = useState(initialState);

    useEffect(() => {
        setStateLocal(prevState => ({ ...prevState, flowVendaTO: props.dados }));
        //eslint-disable-next-line
    }, []);

    const toLocalDateString = useCallback((str: number, maxFract?: number) => {
        return str.toLocaleString('pt-BR', { minimumFractionDigits: maxFract ?? 2, maximumFractionDigits: maxFract ?? 2 });
    }, []);


    const header = () => {
        return (
            <View style={{
                flexDirection: 'row',
                justifyContent: "space-between",
            }}>
                <Text style={[styles.minText, styles.bold]}>Estabelecimento:
                    <Text style={{
                        fontWeight: 'normal',
                    }}>{estabelecimentoDescritivo}</Text></Text>

                <Text style={[styles.minText]}>
                    Impresso por {userInfo?.username} em {stateLocal.flowVendaTO.emissao ? showOnlyDate(new Date().toString()) : ""} às {getCurrentTime()}
                </Text>
            </View>
        );
    };

    const getTitulo = (title: string, bigFontSize: boolean) => {
        return (
            <View style={{
                borderTop: "1px solid black",
                borderBottom: '1px solid black',
                alignItems: 'center',
            }}>
                <Text style={[styles.bold, { fontSize: bigFontSize ? '14px' : "10px" }]}>{title}</Text>
            </View>
        );
    };

    const renderTamanhos = (produtos: TFlowProdutoImpressaoTO) => {
        return (
            <View style={{ flexDirection: 'column' }}>
                <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', backgroundColor: '#ededed' }}>
                    {produtos.tamanhos.map((tamanhoUnico, index) => (
                        <Text key={`tamanho-${index}`} style={[styles.minText, { width: 4 * tamanhoUnico.tamanho.length + 18, textAlign: 'center', padding: 4.5, alignSelf: 'center' }]}>{tamanhoUnico.tamanho}</Text>
                    ))}
                </View>
                {produtos.cores.map((cor, index) => (
                    <View key={`cor-${cor.cor}-${index}`} style={{ flexDirection: 'row' }}>
                        {produtos.tamanhos.map((tamanhoUnico, idx) => {
                            return (
                                <Text
                                    key={`quantidade-${tamanhoUnico.tamanho}-${idx}`}
                                    style={[styles.minText, {
                                        width: 4 * tamanhoUnico.tamanho.length + 18,
                                        textAlign: 'center',
                                        alignSelf: 'center'
                                    }]}>
                                    {cor.tamanhos.find(tam => tam.tamanho === tamanhoUnico.tamanho)?.quantidade ?? '0'}
                                </Text>
                            );
                        })}
                    </View>
                ))}
            </View>
        );
    };


    const pedidoLeftSide = () => {
        return (
            <View style={{ flex: 1 }}>
                <View style={[styles.oneOutOfEight, { flexDirection: 'row' }]}>
                    <Text style={[styles.minText, { width: "30%" }]}>Pedido: </Text>
                    <Text style={[styles.minText, { width: "15%" }]}>{`${stateLocal.flowVendaTO.numero.length ? stateLocal.flowVendaTO.numero : "0"}/${stateLocal.flowVendaTO.numeroEntrega}`}</Text>
                    <View style={[styles.minText, { flexDirection: 'row', }]}>
                        <Text style={[styles.minText,]}>Status: </Text>
                        <Text style={[styles.minText, { paddingLeft: '5%' }]}>{`${stateLocal.flowVendaTO.statusPedido ?? ""}`}</Text>
                    </View>
                </View>
                <View style={[styles.oneOutOfEight, { flexDirection: 'row' }]}>
                    <Text style={[styles.minText, { width: "30%" }]}>Cliente: </Text>
                    <Text style={[styles.minText]}>{`${stateLocal.flowVendaTO.cliente?.descritivo.substring(0, 60).concat("...") ?? ""}`}</Text>
                </View>
                <View style={[styles.oneOutOfEight, { flexDirection: 'row' }]}>
                    <Text style={[styles.minText, { width: "30%" }]}>Nome fantasia: </Text>
                    <Text style={[styles.minText]}>{`${stateLocal.flowVendaTO.estabelecimento.nomeFantasia ?? ""}`}</Text>
                </View>
                <View style={[styles.oneOutOfEight, { flexDirection: 'row' }]}>
                    <Text style={[styles.minText, { width: "30%" }]}>Endereço: </Text>
                    <Text style={[styles.minText]}>{`${stateLocal.flowVendaTO.estabelecimento.endereco ?? ""}`}</Text>
                </View>
                <View style={[styles.oneOutOfEight, { flexDirection: 'row' }]}>
                    <Text style={[styles.minText, { width: "30%" }]}>Complemento: </Text>
                    <Text style={[styles.minText]}>{`${stateLocal.flowVendaTO.estabelecimento.complemento ?? ""}`}</Text>
                </View>
                <View style={[styles.oneOutOfEight, { flexDirection: 'row' }]}>
                    <Text style={[styles.minText, { width: '30%' }]}>E-mail: </Text>
                    <Text style={[styles.minText]}>{`${stateLocal.flowVendaTO.estabelecimento.email ?? ""}`}</Text>
                </View>
                <View style={[styles.oneOutOfEight, { flexDirection: 'row' }]}>
                    <Text style={[styles.minText, { width: "30%" }]}>Representante: </Text>
                    <Text style={[styles.minText]}>{`${stateLocal.flowVendaTO.vendedores.length > 0 ? stateLocal.flowVendaTO.vendedores[0]?.descritivo.split(' - ')[1] : ""}`}</Text>
                </View>

            </View>
        );
    };

    const pedidoRightSide = () => {
        return (
            <View style={{ flex: 1 }}>
                <View style={[styles.oneOutOfEight, { flexDirection: 'row' }]}>
                    <Text style={[styles.minText, { width: "30%" }]}>Emissão: </Text>
                    <Text style={[styles.minText]}>{stateLocal.flowVendaTO.emissao ? showOnlyDate(stateLocal.flowVendaTO.emissao.toString()) : ""}</Text>
                    <View style={[styles.minText, { flexDirection: 'row', width: '60%', justifyContent: "flex-end", paddingRight: '3%' }]}>
                        <Text style={[styles.minText]}>Entrega: </Text>
                        <Text style={[styles.minText, { paddingLeft: '5%' }]}>{stateLocal.flowVendaTO.entrega ? showOnlyDate(stateLocal.flowVendaTO.entrega.toString()) : ""}</Text>
                    </View>
                </View>
                <View style={[styles.oneOutOfEight, { flexDirection: 'row' }]}>
                    <Text style={[styles.minText, { width: '30%' }]}>Tabela de preço: </Text>
                    <Text style={[styles.minText]}>{`${stateLocal.flowVendaTO.tabelaPreco?.descritivo ?? ""}`}</Text>
                </View>
                <View style={[styles.oneOutOfEight, { flexDirection: 'row' }]}>
                    <Text style={[styles.minText, { width: "30%" }]}>Telefone: </Text>
                    <Text style={[styles.minText]}>{`${stateLocal.flowVendaTO.estabelecimento.telefone ?? ""}`}</Text>
                </View>
                <View style={[styles.oneOutOfEight, { flexDirection: 'row' }]}>
                    <Text style={[styles.minText, { width: "30%" }]}>Inscrição estadual: </Text>
                    <Text style={[styles.minText]}>{`${stateLocal.flowVendaTO.estabelecimento.inscricaoEstadual ?? ""}`}</Text>
                </View>
                <View style={[styles.oneOutOfEight, { flexDirection: 'row' }]}>
                    <Text style={[styles.minText, { width: "30%" }]}>Município: </Text>
                    <Text style={[styles.minText]}>{`${stateLocal.flowVendaTO.estabelecimento.municipio ?? ""}`}</Text>
                </View>
                <View style={[styles.oneOutOfEight, { flexDirection: 'row' }]}>
                    <Text style={[styles.minText, { width: "30%" }]}>CEP: </Text>
                    <Text style={[styles.minText]}>{`${stateLocal.flowVendaTO.estabelecimento.cep ?? ""}`}</Text>
                </View>
                <View style={[styles.oneOutOfEight, { flexDirection: 'row' }]}>
                    <Text style={[styles.minText, { width: "30%" }]}>Forma de comissão: </Text>
                    <Text style={[styles.minText]}>{`${stateLocal.flowVendaTO.formaComissao?.descritivo ?? ""}`}</Text>
                </View>

            </View>
        );
    };

    const getValorTotal = useCallback((valorUnitario: string, quantidade: number, desconto: string) => {
        valorUnitario = valorUnitario.replace(",", ".");
        desconto = desconto.replace(",", ".");
        return (Number(valorUnitario) * quantidade) - Number(desconto);
    }, []);

    const renderProdutos = () => {
        return stateLocal.flowVendaTO.flowItens.produtos.map((e, i) => {
            return (
                <View
                    key={i}
                    wrap={false}
                    style={{
                        marginTop: i > 0 ? 5 : 0,
                        borderBottom: i === (stateLocal.flowVendaTO.flowItens.produtos.length - 1) ? "" : '1px solid black',
                    }}>
                    <Text style={[styles.minText, { backgroundColor: '#ededed', padding: 4 }]}>{e.nome}</Text>
                    <View style={{ flexDirection: 'row', gap: 10 }}>
                        <View style={{ width: '50%' }}>
                            <View style={{ flexDirection: 'row', }}>
                                <Text style={[styles.minText, { width: "44%" }]}>Cor</Text>
                                <Text style={[styles.minText, { width: "14%", textAlign: 'center' }]}>Valor Unitário</Text>
                                <Text style={[styles.minText, { width: "14%", textAlign: 'center' }]}>Desconto</Text>
                                <Text style={[styles.minText, { width: "14%", textAlign: 'center' }]}>Valor Total</Text>
                                <View style={{ width: "14%" }}>
                                    <Text style={[styles.minText, { textAlign: 'center', paddingRight: 5 }]}>Quantidade</Text>
                                    <Text style={[styles.minText, { textAlign: 'center', paddingRight: 5 }]}>total</Text>
                                </View>
                            </View>
                            {e.cores.map((cor) => {
                                return (
                                    <View style={{ flexDirection: 'row' }}>
                                        <Text style={[styles.minText, { width: "44%" }]}>{cor.cor}</Text>;
                                        <Text style={[styles.minText, { width: "14%", textAlign: 'center' }]}>{(cor.valorUnitario)}</Text>
                                        <Text style={[styles.minText, { width: "14%", textAlign: 'center' }]}>{(cor.desconto)}</Text>
                                        <Text style={[styles.minText, { width: "14%", textAlign: 'center' }]}>{toLocalDateString(getValorTotal(cor.valorUnitario, cor.quantidade, cor.desconto))}</Text>
                                        <Text style={[styles.minText, { width: "14%", textAlign: 'center' }]}>{(cor.quantidade)}</Text>
                                    </View>
                                );
                            })}
                        </View>
                        <View style={{ width: "50%", flexDirection: 'row' }}>
                            {renderTamanhos(stateLocal.flowVendaTO.flowItens.produtos[i])}
                        </View>
                    </View>
                </View>
            );
        });
    };

    const renderTotalizador = () => {
        return (
            <>
                <View style={{ borderTop: "1px solid black", alignItems: 'center', backgroundColor: '#9E9E9E' }} />
                <View style={{
                    flexDirection: 'row'
                }}>
                    <View style={{
                        width: "80%",
                        height: "100%",
                    }}>
                        <Text style={[styles.minText]}>Observação:</Text>
                        <Text style={[styles.minText]}>{stateLocal.flowVendaTO.observacao ?? ""}</Text>
                    </View>

                    <View style={{
                        width: "20%",
                        height: "100%",
                    }}>
                        <View style={[styles.minText, styles.row]}>
                            <Text style={[styles.footerTitle]}>Quantidade total:</Text>
                            <Text>{stateLocal.flowVendaTO.itens.reduce((prev, act) => prev += act.quantidade, 0)}</Text>
                        </View>
                        <View style={[styles.minText, styles.row]}>
                            <Text style={[styles.footerTitle]}>Valor total:</Text>
                            <Text>{toLocalDateString(stateLocal.flowVendaTO.valorTotalBruto)}</Text>
                        </View>
                        <View style={[styles.minText, styles.row]}>
                            <Text style={[styles.footerTitle]}>Desconto em R$:</Text>
                            <Text>{toLocalDateString(stateLocal.flowVendaTO.pedidoDesconto.valorDesconto)}</Text>
                        </View>
                        <View style={[styles.minText, styles.row]}>
                            <Text style={[styles.footerTitle]}>Desconto em %:</Text>
                            <Text>{toLocalDateString(stateLocal.flowVendaTO.pedidoDesconto.percentualDesconto)}</Text>
                        </View>
                        <View style={[styles.minText, styles.row]}>
                            <Text style={[styles.footerTitle]}>Valor Liquido:</Text>
                            <Text>{toLocalDateString(stateLocal.flowVendaTO.valorTotalLiquido)}</Text>
                        </View>
                    </View>
                </View>
            </>
        );
    };

    const calculateTotalValues = (pagamentos: IPagamentoRow[]): Map<string, { totalValue: number, count: number; }> => {
        const totalValues = new Map<string, { totalValue: number, count: number; }>();

        pagamentos.forEach(pagamento => {
            const { descritivoFormaPagamento, valor } = pagamento;
            const currentTotal = totalValues.get(descritivoFormaPagamento);
            if (currentTotal) {
                currentTotal.totalValue += valor;
                currentTotal.count++;
            } else {
                totalValues.set(descritivoFormaPagamento, { totalValue: valor, count: 1 });
            }
        });

        return totalValues;
    };

    const totalValues = calculateTotalValues(stateLocal.flowVendaTO.itensPagamento);

    const renderPagamentos = () => {
        return (
            <View style={{ flexDirection: 'row', justifyContent: "space-between", width: '100%', minHeight: '100px' }}>
                <View style={{ width: "50%" }}>
                    <View style={[styles.oneOutOfEight, { flexDirection: 'column', flex: 1 }]}>
                        <Text style={[styles.minText]}>Formas de pagamento: </Text>
                        {Array.from(totalValues.entries()).map(([descritivo, { totalValue, count }]) => (
                            <Text key={descritivo} style={[styles.minText, { paddingTop: 2 }]}>
                                {`${descritivo} ${count}X - ${currencyOf(totalValue)}`}
                            </Text>
                        ))}
                    </View>

                </View>

                <View style={{ width: "50%" }}>
                    <View style={[styles.oneOutOfEight, { flexDirection: 'row', justifyContent: 'flex-end' }]}>
                        <Text style={[styles.minText]}>Condição de pagamento: </Text>
                        {<Text style={[styles.minText]}>{`${stateLocal.flowVendaTO.flowItens.condicaoPagamentos.join("/") ?? 0}`}</Text>}
                    </View>
                </View>
            </View>
        );
    };

    return (
        <>
            <IconButton
                sx={{
                    zIndex: 100000,
                    position: 'absolute',
                    bottom: 20,
                    right: 30,
                    backgroundColor: 'var(--laranja)',
                    ':hover': {
                        backgroundColor: 'var(--laranja)',
                        filter: 'grayscale(.2) brightness(.8) contrast(1.5)'
                    }
                }}
                onClick={props.onGoBack}
            >
                <CloseIcon sx={{ color: '#fff', fontSize: 45 }} />
            </IconButton>

            <PDFViewer
                style={{
                    zIndex: 10000,
                    width: '100%',
                    height: '100vh',
                    border: 0,
                    position: 'absolute',
                    top: 0,
                    left: 0,
                }}
                showToolbar={true}
            >
                <Document
                    title='A definir'
                    author='André Franco'
                    language='pt-BR'
                >
                    <Page
                        size={'A4'}
                        orientation='landscape'
                        wrap={true}
                        style={{
                            backgroundColor: "#FFF",
                            display: 'flex',
                            alignItems: 'center',
                            minHeight: "100vh",
                        }}
                        break
                    >
                        <View style={{
                            padding: 20,
                        }}>
                            {header()}
                            {getTitulo("Pedido de venda", true)}
                            <View style={{ flexDirection: 'row', height: '100px' }}>
                                {pedidoLeftSide()}
                                {pedidoRightSide()}
                            </View>
                            {getTitulo("Produtos", false)}
                            {renderProdutos()}
                            {getTitulo("Pagamentos", false)}
                            {renderPagamentos()}
                            {renderTotalizador()}
                        </View>
                        <View
                            fixed
                            style={{
                                width: '95%',
                                position: 'absolute',
                                bottom: 10,
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                                gap: '10'
                            }}>
                            <View style={{
                                width: '100px',
                                height: '25px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: '#FF7F01',
                                paddingHorizontal: 5,
                                borderRadius: '5px'
                            }}>
                                <Image
                                    src={'assets/logoFlow.png'}
                                />
                            </View>
                            <Text
                                style={{
                                    fontSize: 10,
                                    color: 'grey',
                                }}
                                render={({ pageNumber, totalPages }) => (
                                    `${pageNumber} / ${totalPages}`
                                )}
                            />
                        </View>
                    </Page>
                </Document>
            </PDFViewer >
        </>
    );
};

export default ImprimirA4;

const styles = StyleSheet.create({
    minText: {
        fontSize: '8px'
    },
    bold: {
        fontWeight: "bold"
    },
    oneOutOfEight: {
        flex: 1 / 7,
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    footerTitle: {
        width: "50%",
        textAlign: 'right'
    }
});
